<template>
  <v-container>
    <v-form :readonly="!canEditSettings">
      <v-card>
        <v-card-title>Drivers</v-card-title>
        <v-card-text>
          <h2 class="mb-3">Driver Table</h2>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field outlined v-model="config.secondaryLocLabel" label="Secondary Location Label"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="config.routingDriverHoursLabel"
                label="Label for Routing Driver Hours"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field outlined v-model="config.prefLabel1" label="Preference Label 1"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined v-model="config.prefLabel2" label="Preference Label 2"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined v-model="config.prefLabel3" label="Preference Label 3"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field outlined v-model="config.prefLabel4" label="Preference Label 4"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined v-model="config.prefLabel5" label="Preference Label 5"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined v-model="config.prefLabel6" label="Preference Label 6"></v-text-field>
            </v-col>
          </v-row>

          <h2 class="mb-3">Find available drivers</h2>

          <v-row dense>
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Do you want to schedule Drivers based on Driver Availability? </v-subheader>
              <v-radio-group v-model="config.useAvailableDrivers" row>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row dense class="bg-blue">
            <v-col cols="12" md="4">
              <v-subheader class="pl-0"> Allow Driver to ACCEPT driver assignment* </v-subheader>
              <v-radio-group row v-model="config.driverAccept" :disabled="!config.useAvailableDrivers">
                <v-radio :value="true">
                  <template v-slot:label> Yes </template>
                </v-radio>
                <v-radio :value="false">
                  <template v-slot:label> No </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <v-subheader class="pl-0"> Allow Driver to DECLINE driver assignment* </v-subheader>
              <v-radio-group row v-model="config.driverDecline" :disabled="!config.useAvailableDrivers">
                <v-radio :value="true">
                  <template v-slot:label> Yes </template>
                </v-radio>
                <v-radio :value="false">
                  <template v-slot:label> No </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-subheader class="pl-0">
                *Selecting Yes for either of these options requires that all drivers be listed in the driver table and
                you will not be allowed to type in a name that is not in the table.
              </v-subheader>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="12">
              <v-subheader class="pl-0">Default Designation to filter available drivers</v-subheader>
              <v-radio-group v-model="config.defaultDesignation" row>
                <v-radio value="All" label="All"></v-radio>
                <v-radio value="None" label="None"></v-radio>
                <v-radio v-for="(des, i) in designations" :key="i" :value="des" :label="des"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row dense class="bg-blue">
            <v-col cols="12" md="12">
              <v-subheader class="pl-0">Default Zone for new drivers</v-subheader>
              <v-radio-group v-model="config.defaultZone" row>
                <v-radio v-for="(zone, i) in zoneOptions" :key="i" :value="zone" :label="zone.text"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-subheader class="pl-0">Location to be used to determine the zone of a trip:</v-subheader>
              <v-radio-group v-model="config.determineZoneBy" row>
                <v-radio label="Request Location" value="request"></v-radio>
                <v-radio label="Vehicle Location" value="vehicle"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense class="bg-blue">
            <v-col cols="12" md="12">
              <v-subheader class="pl-0">
                Select weekly rotation to use the number of trips and hours from the trips already assigned to the
                drivers for the week of the trip. Select continuous rotation to use cumulative trips and hours that are
                accumulated on the driver table. For continuous rotation, number trips and hours may be adjusted to
                allow new drivers to fit in the rotation.
              </v-subheader>
              <v-radio-group v-model="config.defaultSortRotation" row>
                <v-radio label="No Rotation" value="none"></v-radio>
                <v-radio label="Weekly Rotation" value="weekly"></v-radio>
                <v-radio label="Continous Rotation" value="continuous"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <v-select
                v-if="config.defaultSortRotation === 'weekly'"
                v-model="config.weeklyRotationStart"
                :items="[
                  { value: 0, text: 'Sunday' },
                  { value: 1, text: 'Monday' },
                  { value: 2, text: 'Tuesday' },
                  { value: 3, text: 'Wednesday' },
                  { value: 4, text: 'Thursday' },
                  { value: 5, text: 'Friday' },
                  { value: 6, text: 'Saturday' },
                ]"
                label="Starting Day"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-subheader class="pl-0">Default sort option for the driver list:</v-subheader>
              <v-radio-group v-model="config.defaultSortOption" row>
                <v-radio
                  v-for="(option, i) in sortOptions"
                  :key="i"
                  :label="option.text"
                  :value="option.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense class="bg-blue">
            <v-col cols="12" md="12">
              <v-subheader class="pl-0"> Display Driver tooltip on Available Drivers? </v-subheader>
              <v-radio-group v-model="config.driverTooltip" row>
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <h2 class="my-3">Driver Access (will include bidding settings)</h2>

          <v-row dense>
            <v-col cols="12">
              <v-subheader class="pl-0"> Allow Driver to enter mileage / hours </v-subheader>
              <v-radio-group row v-model="config.enterMileage">
                <v-radio :value="true">
                  <template v-slot:label> Yes </template>
                </v-radio>
                <v-radio :value="false">
                  <template v-slot:label> No </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';

export default {
  mixins: [],
  inject: ['eventHub'],
  components: {},
  data: () => ({
    config: {
      secondaryLocLabel: '',
      routingDriverHoursLabel: '',
      prefLabel1: 'AM?',
      prefLabel2: 'Mid Day?',
      prefLabel3: 'PM?',
      prefLabel4: 'Overnight?',
      prefLabel5: 'Weekend?',
      prefLabel6: 'Non-School?',
      defaultDesignation: '',
      defaultZone: '',
      useAvailableDrivers: true,
      driverAccept: false,
      driverDecline: false,
      defaultSortRotation: 'none',
      weeklyRotationStart: '',
      defaultSortOption: 0,
      driverTooltip: true,

      enterMileage: false,
      determineZoneBy: 'request',
    },
    sortOptions: [
      { text: 'Trips/Name', value: 0 },
      { text: 'Trips/Seniority', value: 1 },
      { text: 'Hours/Name', value: 2 },
      { text: 'Hours/Seniority', value: 3 },
      { text: 'Seniority', value: 4 },
    ],
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
    ...mapGetters('driver', ['designations']),
    ...mapGetters('location', ['zones']),
    zoneOptions() {
      return this.zones.filter((e) => e.text !== 'No Zones yet');
    },
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.driverConfig = await this.getConfig('driver');
      this.config = { ...this.config, ...this.driverConfig };
    },
    async save() {
      try {
        const r = await this.setConfig({ name: 'driver', value: this.config });
        if (r && r.done) {
          this.$myalert.success('Driver config options saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
  created() {
    this.eventHub.$on('saveDriverConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveDriverConfig');
  },
  watch: {
    'config.useAvailableDrivers'(value) {
      if (!value) {
        this.config.driverAccept = false;
        this.config.driverDecline = false;
      }
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.bg-blue {
  background-color: aliceblue;
}
.inline {
  display: inline-block;
}
.text-field {
  max-width: 100px;
}
/* .checkbox-wrapper {
  display: flex;
  align-items: center !important;
}
.checkbox {
  margin-right: auto;
} */
</style>
